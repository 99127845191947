@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap');

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

* {
    box-sizing: border-box;
}

*,
:hover {
    outline: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

body {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    color: var(--site-color);
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}



.login-wrapper {
    background-image: linear-gradient(var(--theme1-bg), var(--theme2-bg));
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

*,
:after,
:before {
    box-sizing: border-box;
}

* {
    outline: 0;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.text-center {
    text-align: center !important;
}

.logo-login {
    display: block;
    widows: 100%;
}

.login-form {
    background-color: var(--primary-bg);
    border-radius: 8px;
    margin: 0 auto;
    max-width: 300px;
    width: 100%;
    padding: 26px 16px 16px 16px;
}

.loginInner1.authentication {
    width: 100%;
}

img {
    vertical-align: middle;
    border-style: none;
}

.logo-login img {
    max-height: 65px;
    max-width: 250px;
}

.loginInner1.authentication .featured-box-login {
    padding: 0;
}

h4 {
    margin-top: 0;
    margin-bottom: .5rem;
}

h4 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h4 {
    font-size: 1.5rem;
}

.login-form h4 {
    color: var(--theme2-bg);
}

.mt-3 {
    margin-top: 1rem !important;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a {
    text-decoration: none;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a,
a:hover,
a:active {
    text-decoration: none;
}

input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input {
    overflow: visible;
}

.otp-input {
    width: 35px;
    height: 40px;
    padding: 4px;
    margin: 0 4px;
    font-size: 24px;
    border-radius: 4px;
    border: 1px solid red !important;
    text-align: center;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-Rjo7S.style-Rjo7S {
    display: flex;
    flex-direction: row;
}

#style-NWE8h.style-NWE8h {
    display: flex;
}

#style-yZwpn.style-yZwpn {
    display: flex;
    align-items: center;
}

#style-EyBMr.style-EyBMr {
    display: flex;
    align-items: center;
}

#style-vtjPW.style-vtjPW {
    display: flex;
    align-items: center;
}

#style-9WODC.style-9WODC {
    display: flex;
    align-items: center;
}

#style-gBsQN.style-gBsQN {
    display: flex;
    align-items: center;
}

#style-yPy5i.style-yPy5i {
    display: flex;
    align-items: center;
}